import './cultural.css';
import React from 'react';
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
function Cultural() {

    return (
        <div className='cultural_'>
            <ScrollToTop />
            {/* <body> */}
            <div>
                <div className="cultural_wave"></div>
                <div className="cultural_wave"></div>
                <div className="cultural_wave"></div>
            </div>
            <Navbar />
            <div className="cultural_container" >
                <div className="cultural_event">
                    <div className="cultural_eventname">
                        <div className="cultural_name">
                            <h2 style={{ fontStyle: "italic" }}>COMEDY NIGHT</h2>
                            <p style={{ fontSize: "1.25rem" }}>A STANDUP COMEDY SHOW BY</p>
                            <h1>RAJAT
                                CHAUHAN
                            </h1>
                            <hr style={{ borderBlockColor: "#F2D8C9" }} />
                        </div>
                        <div className="cultural_image">
                            <img src="https://sosimg.sgp1.cdn.digitaloceanspaces.com/artist-gallery/9595464_1657792428.jpg" alt="RAJAT CHAUHAN" />
                        </div>



                    </div>

                </div>

            </div>

            <div className="cultural_faqs">
                FAQs
            </div>
            <details>
                <summary>
                    What is the timings of the show?
                    <span className="cultural_icon">👇</span>
                </summary>
                <div className='cultural_stats'>
                    <p>
                        25 November 2023 (7:30 pm - 8:05 pm)
                    </p>
                </div>
            </details>
            <details>
                <summary>
                    What is the venue of show?
                    <span className="cultural_icon">👇</span>
                </summary>
                <div className='cultural_stats'>
                    <p>
                        This field will be updated soon....
                    </p>
                </div>
            </details>

            <div className="cultural_footer">
                <div className="cultural_copyright">
                    <p>&copy; copyright all rights reserved</p>
                </div>
                <div className="cultural_links">

                </div>
                <div className="cultural_socialmedia">

                </div>
            </div>

        </div>
    );
}

export default Cultural;
