import "./bizblitz.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index";
const firestore = getFirestore(app);

function Bizblitz() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(collref, where("event_participated", "==", "bizblitz"));
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "bizblitz",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signin");
    }
  };
  return (
    <div className="bizblitz_">
      <ScrollToTop />
      {/* <body> */}
      <div>
        <div className="bizblitz_wave"></div>
        <div className="bizblitz_wave"></div>
        <div className="bizblitz_wave"></div>
      </div>
      <Navbar />
      <div className="bizblitz_container">
        <div className="bizblitz_event">
          <div className="bizblitz_eventname">
            <div className="bizblitz_name">
              <h2>BIZBLITZ</h2>
            </div>
          </div>
          <div className="bizblitz_eventname">
            <p>
              A creative fusion of fashion and food innovations. Attendees can
              explore stalls featuring the latest fashion trends and culinary
              delights, providing a delightful sensory experience.
            </p>
          </div>
          <div className="bizblitz_eventname">
            <button className="bizblitz_reg" onClick={handleRegister}>
              Register &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="bizblitz_eventinfo">
        <div className="bizblitz_cont">
          <div className="bizblitz_vision">
            <hr />
            <div className="bizblitz_headinfo">
              <h2>VISON</h2>
            </div>
            <hr />
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque
              asperiores perferendis voluptatem reprehenderit distinctio vero
              nihil ex adipisci modi, assumenda ullam quasi rerum minus eos
              temporibus, at quis quod nam!
            </p>
          </div>
          <div className="bizblitz_outlines">
            <div className="bizblitz_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="bizblitz_headinfo">
              <p>OUTLINES</p>
            </div>
            <hr />
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
              provident magni illum iste adipisci, at nesciunt ex obcaecati eos
              est doloribus ab sit odit, eaque quos similique totam, delectus
              sed.
            </p>
          </div>
          <div className="bizblitz_guidelines">
            <div className="bizblitz_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="bizblitz_headinfo">
              <p>GUIDELINES</p>
            </div>
            <hr />
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae,
              autem, repellat, vel et debitis modi optio voluptas error
              repellendus doloremque aut velit inventore fugiat animi tempore
              deserunt. Unde, dolor similique!
            </p>
          </div>
        </div>
      </div>
      <div className="bizblitz_faqs">FAQs</div>
      {/* <div className="collapse">
        <div className="date">
            <button type="button" className="collapsible">
                <p>What is the deadline of presentation?</p>
            </button>
            <div className="content">
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti dolorum repellendus vero harum!</p>
            </div>
        </div>
        <div className="venue">
            <button type="button" className="collapsible">
                <p>What is the venue of presentation?</p>
            </button>
            <div className="content">
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti dolorum repellendus vero harum!</p>
            </div>
        </div>
  </div> */}
      {/* <ul className='accordian'>
        <li>
            <input type='radio' name='accordian' id='first' />
            <label for='first'>Products</label>
            <div className='answer'>
                <p>This will be soon updated.</p>
            </div>
        </li>
        <li>
            <input type='radio' name='accordian' id='second' />
            <label for='second'>Information</label>
            <div className='answer'>
                <p>This will be soon updated.</p>
            </div>
        </li>
    </ul> */}
      <details>
        <summary>
          What is the deadline of presentation?
          <span className="bizblitz_icon">👇</span>
        </summary>
        <div className="bizblitz_stats">
          <p>This field will be updated soon....</p>
        </div>
      </details>
      <details>
        <summary>
          What is the venue of presentation?
          <span className="bizblitz_icon">👇</span>
        </summary>
        <div className="bizblitz_stats">
          <p>This field will be updated soon....</p>
        </div>
      </details>

      <div className="bizblitz_footer">
        <div className="bizblitz_copyright">
          <p>&copy; copyright all rights reserved</p>
        </div>
        <div className="bizblitz_links"></div>
        <div className="bizblitz_socialmedia"></div>
      </div>
      {/* <script src='\src\Register.js'></script> */}
      {/* </body> */}
    </div>
  );
}

export default Bizblitz;
