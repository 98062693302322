import React from 'react';
import './footer.css';

function Footer() {
    return (
        <section className="footer">
            <div className="footer-row">
                <div className="footer-col">
                    <h4>Info</h4>
                    <ul className="links">
                        <li><a href="/#about">About Us</a></li>
                        <li><a href="/#events">Events</a></li>
                        <li><a href="/#speakers">Speakers</a></li>
                        <li><a href="/#contacts">Our Team</a></li>
                    </ul>
                </div>

                <div className="footer-col">
                    <h4>CONTACT US</h4>
                    <ul className="links">
                        <li><a href='mailto:iic@mnnit.ac.in'>Email  : iic@mnnit.ac.in</a></li>
                        <li><a href="https://maps.app.goo.gl/bGStnAYzRvWpAvGV7">Address: INSTITUTION'S INNOVATION COUNCIL<br /> Motilal Nehru National <br />
                            Institute of Technology Allahabad<br />
                            Prayagraj - 211004, INDIA</a></li>
                    </ul>
                </div>

                <div className="footer-col">
                    <h4>Quick Links</h4>
                    <ul className="links">
                        <li><a href="http://www.mnnit.ac.in/iip/iic/index.html"> IIC MNNIT</a></li>
                        <li><a href="#"></a></li>
                        <li><a href="http://www.mnnit.ac.in/">MNNIT Official</a></li>
                    </ul>
                </div>

                <div className="footer-col">
                    <img src="assets/Team-members/Udbhav_logo_light.png" alt="Udhav_LOGO" width="250" height="100" />

                    <div className="icons" >
                        <i className="fa fa-brands fa-facebook-f"></i>
                        <a href="https://www.instagram.com/iic_mnnit/"><i className="fa fa-brands fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/iicmnnitallahabad/"><i className="fa fa-brands fa-linkedin"></i></a>

                    </div>
                    <div>
                        <p className="p_style" > &copy; All Rights Reserved by IIC MNNIT 2023. </p>
                    </div>
                </div>
            </div>
            <hr />
            <hr />
        </section>
    );
}

export default Footer;