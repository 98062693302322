import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/index";


let authUser;

onAuthStateChanged(auth, (user) => {
    if (user) {
        authUser = user;
    } else {
        authUser = null;
    }
});
const getAuthUser = () => authUser;

export { authUser, getAuthUser };
