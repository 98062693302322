import "./cohort.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index";
const firestore = getFirestore(app);

function Cohort() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(
        collref,
        where("event_participated", "==", "cohort")
      );
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "cohort",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signin");
    }
  };
  return (
    <div className="cohort_">
      <ScrollToTop />
      {/* <body> */}
      <div>
        <div className="cohort_wave"></div>
        <div className="cohort_wave"></div>
        <div className="cohort_wave"></div>
      </div>
      <Navbar />
      <div className="cohort_container">
        <div className="cohort_event">
          <div className="cohort_eventname">
            <div className="cohort_name">
              <h2>
                Cohort Experience: Nurturing Innovation, Fostering Excellence
              </h2>
            </div>
          </div>
          <div className="cohort_eventname">
            <p>
              This phase is where ideas take root, blossom, and transform into
              innovative solutions that have the power to change the world. The
              Cohort is not just a part of the event; it's a unique journey
              designed to guide, inspire, and elevate your innovative spirit.
              The Cohort Experience is not just a phase; it's an invaluable
              opportunity to transform your innovative ideas into tangible,
              impactful solutions. Through mentorship, collaboration, and skill
              development, the Cohort sets the stage for success during the main
              event. Join us in this immersive experience, where innovation
              knows no bounds, and your journey towards shaping the future
              begins.
            </p>
            <p></p>
          </div>
          <div className="cohort_eventname">
            <button className="cohort_reg" onClick={handleRegister}>
              Register Now &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="cohort_eventinfo">
        <div className="cohort_cont">
          <div className="cohort_vision">
            <hr />
            <div className="cohort_headinfo">
              <h2> Eligibility</h2>
            </div>
            <hr />
            <ul>
              <li>
                Open to all students of currently pursuing an undergraduate or
                postgraduate degree. (TYPE 1.0)
              </li>
              <li>Open to all start-ups and entrepreneurs (TYPE 2.0)</li>
              <li>
                Participants must register for the event to participate in the
                Cohort. All the team members have to register on the website for
                the event and the google form must be filled only by the team
                leader.
              </li>
            </ul>
            {/* <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque asperiores perferendis voluptatem reprehenderit distinctio vero nihil ex adipisci modi, assumenda ullam quasi rerum minus eos temporibus, at quis quod nam!</p> */}
          </div>
          <div className="cohort_outlines">
            <div className="cohort_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Team Formation</h2>
            </div>
            <hr />
            <ul>
              <li>Teams can comprise 2-5 members.</li>
              <li>
                Cross-disciplinary teams are encouraged for diverse
                perspectives.
              </li>
            </ul>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa provident magni illum iste adipisci, at nesciunt ex obcaecati eos est doloribus ab sit odit, eaque quos similique totam, delectus sed.</p> */}
          </div>
          <div className="cohort_guidelines">
            <div className="cohort_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2> Selection Process</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>
                Shortlisted teams will be announced by [Notification Date].
              </li>
              <li>Selected teams will move on to the pitch phase.</li>
            </ul>
          </div>
          <div className="cohort_guidelines">
            <div className="cohort_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Cohort Participation</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>
                Engage in mentorship sessions and workshops during the Cohort
                phase.
              </li>
              <li>Refine and enhance your ideas with expert guidance.</li>
            </ul>
          </div>
          <div className="cohort_guidelines">
            <div className="cohort_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Pitch Deck Guidelines - TYPE 1.0 (College Students)</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>Craft a compelling pitch presentation.</li>
              <li>
                Emphasize the problem, solution, and uniqueness of your idea.
              </li>
              <li>Showcase a prototype or demo if applicable.</li>
              <li>Teams will present during the Udbhav event.</li>
            </ul>
          </div>
          <div className="cohort_guidelines">
            <div className="cohort_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Pitch Deck Guidelines - TYPE 2.0 (Startups)</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>Develop an investor-centric pitch presentation.</li>
              <li>
                Clearly define the problem, present your solution, and emphasize
                market opportunity.
              </li>
              <li>Provide evidence of market validation and traction.</li>
              <li>
                Teams will present to potential investors during the Udbhav
                event.
              </li>
            </ul>
          </div>
          <div className="cohort_guidelines">
            <div className="scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Awards and Recognition</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>Outstanding ideas and presentations will be acknowledged.</li>
              <li>Prizes and opportunities await the most innovative minds.</li>
            </ul>
          </div>
          <div className="cohort_guidelines">
            <div className="scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="cohort_headinfo">
              <h2>Event Etiquette</h2>
            </div>
            <hr />
            {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae, autem, repellat, vel et debitis modi optio voluptas error repellendus doloremque aut velit inventore fugiat animi tempore deserunt. Unde, dolor similique!</p> */}
            <ul>
              <li>Respectful behavior is expected at all times.</li>
              <li>Collaboration and openness to feedback are encouraged.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="cohort_faqs">FAQs</div>
      <details>
        <summary>
          What is the timeline of the event?
          <span className="cohort_icon">👇</span>
        </summary>
        <div className="cohort_stats">
          <ul>
            <li>
              Adhere to the specified timeline for idea submission, cohort
              participation, and pitch presentation.
            </li>
            <li>Late submissions may not be considered.</li>
          </ul>
        </div>
      </details>
      <details>
        <summary>
          What is the Evaluation Criteria for the event?
          <span className="cohort_icon">👇</span>
        </summary>
        <div className="cohort_stats">
          <ul>
            <li>
              Ideas will be assessed based on innovation, feasibility, and
              potential impact.
            </li>
            <li>Judges' decisions during the pitch sessions are final.</li>
          </ul>
        </div>
      </details>

      <div className="cohort_footer">
        <div className="cohort_copyright">
          <h2>Disclaimer</h2>
          <ul>
            <li>
              The organizing committee reserves the right to modify rules if
              necessary.
            </li>
            <li>
              All participants are expected to adhere to the rules and decisions
              of the organizers.
            </li>
          </ul>
        </div>
        <div className="cohort_links"></div>
        <div className="cohort_socialmedia"></div>
      </div>
      {/* <script src='\src\Register.js'></script> */}
      {/* </body> */}
    </div>
  );
}

export default Cohort;
