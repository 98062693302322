import React, { useCallback, useEffect } from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import * as particlesOptions from '../Home/particles.json';
import './finalpro.css';
import './style2.css';

function Contacts() {
    const particlesInit = useCallback(async (engine) => {
        // console.log(engine);
        await loadFull(engine);
    }, []);
    const particlesLoaded = useCallback(async (container) => {
        // console.log(container);
    }, []);


    return (
        <div id='contacts' className='contact_container'>
            <div className='bg_container2'><Particles id='tsparticles3' options={particlesOptions} init={particlesInit} loaded={particlesLoaded} /></div>
            <h1 className="contact_h1">Our Team</h1>
            <h2 className="contact_h2">Festival Secretary</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/sajal.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style">Sajal Kumar Babu Degala</h3>
                    <div className="contact_btn">

                        <a href="mailto:sajal.kumar3@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8688251789" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>


                </div>

                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/anshika.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">Anshika Pattnaik</h3>
                    <div className="contact_btn">

                        <a href="mailto:anshikapattnaik190441@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9407707496" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/aditya jain.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">Aditya Jain</h3>
                    <div className="contact_btn">

                        <a href="mailto:adityajain3182@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9335780943" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

                <div className="contact_team-member">
                    <div className="contact_timage">

                        <img src="assets/Team-members/stuti.jpg" alt="Member 2" />
                    </div>
                    <h3 className="contact_h3_style">Stuti Jain</h3>
                    <div className="contact_btn">

                        <a href="mailto:stuti.20203150@mnnit.ac.in" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:7898559557" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
            </div>

            <h2 className="contact_h2">Web Team Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/web team.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style">Priya Pachauri</h3>
                    <div className="contact_btn">

                        <a href="mailto:priyapachauri200@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:7078329116" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
            </div>
            <h2 className="contact_h2">PR Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/aditya .jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style"> Aditya Singh</h3>
                    <div className="contact_btn">
                        <a href="mailto:aditya.rns09@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8957434359" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
            </div>
            <h2 className="contact_h2">Hospitality Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/rahul.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style"> Rahul Modi</h3>
                    <div className="contact_btn">

                        <a href="mailto:rmodi8168@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9407707496" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <h2 className="contact_h2">Collaboration Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/dolly.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style"> Dolly Agrawal</h3>
                    <div className="contact_btn">

                        <a href="mailto:dolly.agrawal020102@gmail.com " target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8979805176" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
            </div>
            <h2 className="contact_h2">Design Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/utkarsh.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style"> Kunwar Utkarsh</h3>
                    <div className="contact_btn">

                        <a href="mailto:kunwarut96@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8377056622" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

            </div>
            <h2 className="contact_h2">Content Head</h2>
            <div className="contact_team-container">
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/Ankit_Image.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style"> Ankit Prakash</h3>
                    <div className="contact_btn">

                        <a href="mailto:ankit.prakash.994@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9993643368" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

            </div>
            <h2 className="contact_h2">Event Coordinators</h2>
            <div className="contact_team-container">

                {/* <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/men.png" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style">Dwijendra Dubey</h3>
                    <div className="contact_btn">

                        <a href="mailto:dwijendrame0042@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9415586887" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/men.png" alt="Member 2" />
                    </div>
                    <h3 className="contact_h3_style">Mohit Kumar Pandey</h3>
                    <div className="contact_btn">

                        <a href="mailto:mohitkumarpandey979@gmail.com  " target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:6387341428" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div> */}
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/sonal.jpg" alt="Member 1" />
                    </div>
                    <h3 className="contact_h3_style">Sonal Kumari</h3>
                    <div className="contact_btn">

                        <a href="mailto:sonal27102000@gmail.com " target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:7733995254" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/Dwijendra Dubey.jpg" alt="Member 2" />
                    </div>
                    <h3 className="contact_h3_style">Dwijendra Dubey</h3>
                    <div className="contact_btn">

                        <a href="mailto:dwijendrame0042@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9415586887" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>

                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/prateek.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">Prateek Sinha</h3>
                    <div className="contact_btn">

                        <a href="mailto:prateeksinha1234@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8860015315" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/biri.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">Biri Singh</h3>
                    <div className="contact_btn">

                        <a href="mailto:birigate85@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8077121381" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/gopi.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">Janjanam Gopi sai chandu</h3>
                    <div className="contact_btn">

                        <a href="mailto:gopisaichandu.janjanam@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9848496902" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/Ashu_photo.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style"> Ashutosh Kumar</h3>
                    <div className="contact_btn">

                        <a href="mailto:kumarashutosh8210@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:8210215259" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/Ambrish Upadhyay.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style"> Ambrish Upadhyay</h3>
                    <div className="contact_btn">

                        <a href="mailto:ambrish.2022mb05@mnnit.ac.in" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9415986158" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>
                <div className="contact_team-member">
                    <div className="contact_timage">
                        <img src="assets/Team-members/bhola.jpg" alt="Member 3" />
                    </div>
                    <h3 className="contact_h3_style">  Bhola Singh</h3>

                    <div className="contact_btn">

                        <a href="mailto:0532bholasingh@gmail.com" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"> <i className="fa contact_fa fa-envelope"></i></button>
                        </a>
                        <a href="tel:9936548831" target="_blank" rel='noreferrer'>
                            <button className="contact_btn"><i className="fa contact_fa fa-phone"></i></button>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Contacts;