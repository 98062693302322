import "./seedstart.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index";
const firestore = getFirestore(app);

function Seedstart() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(
        collref,
        where("event_participated", "==", "seed_start")
      );
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "seed_start",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signin");
    }
  };
  return (
    <div className="seedstart_">
      <ScrollToTop />
      {/* <body> */}
      <div>
        <div className="seedstart_wave"></div>
        <div className="seedstart_wave"></div>
        <div className="seedstart_wave"></div>
      </div>
      <Navbar />
      <div className="seedstart_container">
        <div className="seedstart_event">
          <div className="seedstart_eventname">
            <div className="seedstart_name">
              <h2 style={{ color: "#0D1526" }}>
                SeedStart Summit: Taking your Venture to the Next Level!
              </h2>
            </div>
          </div>
          <div className="seedstart_eventname">
            <p>
              Join us at the SeedStart Summit, a vibrant chapter within the
              Youth Summit-Udbhav, proudly hosted by the Institution's
              Innovation Council (IIC). The SeedStart Summit is not just an
              event; it's a convergence of innovation, entrepreneurship, and
              opportunity. Entrepreneurs, startups, and creative minds gather in
              this dynamic setting to showcase, connect, and thrive. Supported
              by our DST-TBI funded Innovation and Incubation Hub, this event is
              the catalyst for startups to elevate their ventures.
            </p>
          </div>
          <div className="seedstart_eventname">
            <button className="seedstart_reg" onClick={handleRegister}>
              Register &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="seedstart_eventinfo">
        <div className="seedstart_cont">
          <div className="seedstart_vision">
            <hr />
            <div className="seedstart_headinfo">
              <h2>Eligibility </h2>
            </div>
            <hr />
            <ul>
              <li>Open to all startups and entrepreneurs.</li>
              <li>
                Participants must register for the SeedStart Summit to
                participate.
              </li>
            </ul>
          </div>
          <div className="seedstart_outlines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2>Business Stalls</h2>
            </div>
            <hr />
            <p>
              Discover a marketplace buzzing with innovation. Mini stalls are
              available for startups to showcase their products and services,
              providing a platform for buying and selling. Engage with potential
              customers, investors, and collaborators in this dynamic business
              space.
            </p>
            <ul>
              <li>Stalls are allocated on a first-come, first-served basis.</li>
              <li>
                Register early to secure your space in the business stalls area.
              </li>
            </ul>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2> DST-TBI Innovation and Incubation Hub</h2>
            </div>
            <hr />
            <p>
              Take advantage of the unparalleled support from our DST-TBI funded
              Innovation and Incubation Hub. With a generous grant of 5 crores
              over the next three years, the Hub is committed to nurturing
              startups and entrepreneurs. This event provides an ideal setting
              for startups to tap into this valuable resource and propel their
              growth.
            </p>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2>Networking Opportunities</h2>
            </div>
            <hr />
            <p>
              {" "}
              Connect with fellow entrepreneurs, industry experts, and potential
              collaborators. The SeedStart Summit fosters a collaborative
              environment, providing ample networking opportunities to expand
              your horizons.
            </p>
            <ul>
              <li>
                Respectful and professional behavior is expected during
                networking sessions.
              </li>
              <li>
                Collaboration and openness to partnerships are encouraged.
              </li>
            </ul>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2> Pitch Sessions</h2>
            </div>
            <hr />
            <p>
              Showcase your startup to a panel of experienced judges.
              Participate in pitch sessions where you can present your business
              idea, receive feedback, and potentially secure investment.
            </p>
            <ul>
              <li>Limited slots are available for pitch sessions.</li>
              <li>
                Interested participants must submit a brief pitch proposal
                during registration.
              </li>
              <li>
                Have to present prototype of the product or service being
                provided by the startup to the judges
              </li>
            </ul>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2>Workshops and Seminars</h2>
            </div>
            <hr />
            <p>
              {" "}
              Gain insights from experts through workshops and seminars designed
              to enhance your entrepreneurial skills. Topics range from business
              development and marketing to funding strategies and scalability.
            </p>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2> Investor Meet</h2>
            </div>
            <hr />
            <p>
              Engage with potential investors seeking promising ventures. The
              SeedStart Summit provides a platform for startups to connect with
              investors who share a passion for innovation and growth.
            </p>
          </div>
          <div className="seedstart_guidelines">
            <div className="seedstart_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="seedstart_headinfo">
              <h2>Some Basic Information</h2>
            </div>
            <hr />
            <ul>
              <li>
                Participants are encouraged to utilize the resources provided by
                the DST-TBI Innovation and Incubation Hub.
              </li>
              <li>
                Participants must comply with all event rules and regulations.
              </li>
              <li>
                Any violation may result in disqualification or removal from the
                event.
              </li>
              <li>
                Participants are responsible for the security of their
                belongings and stalls.
              </li>
              <li>
                Follow safety protocols and guidelines provided by event
                organizers.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="seedstart_faqs">FAQs</div>
      <details>
        <summary>
          What is the schedule of the event?
          <span className="seedstart_icon">👇</span>
        </summary>
        <div className="seedstart_stats">
          <ul>
            <li>
              Adhere to the specified schedule for pitch sessions, workshops,
              and networking events.
            </li>
            <li>Late arrivals may not be accommodated.</li>
          </ul>
        </div>
      </details>
      <details>
        <summary>
          What is the stall allocation procedure?
          <span className="seedstart_icon">👇</span>
        </summary>
        <div className="seedstart_stats">
          <ul>
            <li>Stalls are allocated on a first-come, first-served basis.</li>
            <li>
              Register early to secure your space in the business stalls area.
            </li>
          </ul>
        </div>
      </details>

      <div className="seedstart_footer">
        <div className="seedstart_copyright"></div>
        <h3>Disclaimer</h3>
        {/* <br /> */}
        <ul>
          <li>
            The organizing committee reserves the right to modify rules if
            necessary.
          </li>
          <li>
            All participants are expected to abide by the rules and decisions of
            the organizers.
          </li>
        </ul>
        <div className="seedstart_links"></div>
        <div className="seedstart_socialmedia"></div>
      </div>
    </div>
  );
}

export default Seedstart;
