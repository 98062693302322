import styled from 'styled-components';

const Nav = styled.div`
    --navbar_height: 70px;

    position: fixed;
    top: 0;
    height: max(var(--navbar_height),5%);
    width: 100vw;
    box-sizing: border-box;
    padding: 3px 20px 3px 20px;
    /* background-color: #216b7e; */
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    animation: ${(props) => (props.scrolled === 1 ? 'changeNav 0ms forwards ease-in' : '')};
    @keyframes changeNav {
        0%{
            box-shadow: 0px 0.5px 2px 0px #040512;
        }
        100%{
            /* background: #1c1e31; */
            background: var(--color5);
            box-shadow: 0px 1px 5px 0px #040512;
        }
    }

    .logo {
        height: 60%;
        cursor: pointer;
    }
    .hamburger-react {
        color: ${props => (props.scrolled === 1 ? 'white' : props.open ? 'white' : 'white')};
        transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0s !important;
        z-index: 100;
    }
`;

const Sidebar = styled.div`
    --sidebar_width: 13em;

    position: fixed;
    right: max(calc(var(--sidebar_width) * -1),-100%);
    padding: 20px;
    top: ${(props) => (props.scrolled === 1 ? 'max(var(--navbar_height),5%)' : '0px')};
    padding-top: ${props => (props.scrolled ? '20px' : 'calc(max(var(--navbar_height),5%) + 20px)')};
    height:${props => (props.scrolled ? 'calc(100vh - max(var(--navbar_height),5%))' : '100vh')};
    width: min(var(--sidebar_width),100%);
    /* background: linear-gradient(45deg, black, #000000bf); */
    background-color: var(--color4);
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    z-index: 99;
    animation: ${props => {
        if (props.open === true)
            return 'fade_in 100ms forwards ease-in';
        else if (props.open === false)
            return 'fade_out 150ms forwards ease-in';
        else return '';
    }};

    @keyframes fade_in {
        0% {
            right: max(calc(var(--sidebar_width) * -1),-100%);
        }
        100% {
            right: 0px;
        }   
    }
    @keyframes fade_out {
        0% {
            right: 0px;
        }
        100% {
            right: max(calc(var(--sidebar_width) * -1),-100%);
        }   
    }
    
    .list {
        list-style-type: none;
        font-size: 1.5em;
        margin: 0px;
        padding: 0px;
        .list-item {
            cursor: pointer;
            margin-bottom: 0.5em;
            width: fit-content;
            font-family: var(--font1);
            color: white;
            background-image: linear-gradient(0deg, white 100%, white 0%);
            background-clip: text;
            -webkit-background-clip: text;
            -moz-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-text-fill-color: transparent;
            a {
                text-decoration: none;
            }
        }
        .list-item::after {
            content: '';
            display: block;
            width: 90%;
            height: 10px;
            /* border-bottom: 2px solid #056b58; */
            border-bottom: 2px solid var(--color3);
            animation: decreaseBarLength 200ms forwards ease-in-out;            
        }
        .list-item:hover {
            animation: itemHover 100ms forwards ease-in;
        }
        .list-item:hover::after {
            animation: increaseBarLength 200ms forwards ease-in-out;
        }
        @keyframes itemHover {
            0% {
                /* background-image: linear-gradient(0deg, #056b58, white); */
                background-image: linear-gradient(0deg, var(--color3), var(--color1));
            }
            100% {
                /* background-image: linear-gradient(0deg, #056b58, white); */
                background-image: linear-gradient(0deg, var(--color3), var(--color1));
            }
        }
        @keyframes increaseBarLength {
            0% {
                width: 90%;
            }
            100% {
                width: 110%;
            }
        }
        @keyframes decreaseBarLength {
            0% {
                width: 110%;
            }
            100% {
                width: 90%;
            }
        }
    }
`;

export { Nav, Sidebar, };