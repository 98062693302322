import './startup.css';
import React from 'react';
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';

function Startup() {

    return (
        <div className='startupspark_'>
            <ScrollToTop />
            <div>
                <div className="startupspark_wave"></div>
                <div className="startupspark_wave"></div>
                <div className="startupspark_wave"></div>
            </div>
            <Navbar />
            <div className="startupspark_container" >
                <div className="startupspark_event">
                    <div className="startupspark_eventname">
                        <div className="startupspark_name">
                            <h2>STARTUP SPARK</h2>
                        </div>
                    </div>
                    <div className="startupspark_eventname">
                        <p>
                            College students would have the opportunity to take
                            lectures from the ones who have been there and done
                            that. They get to meet great entrepreneurs which might be
                            the foundation stone to thier ventures.

                        </p>

                    </div>
                    <div className="startupspark_eventname">
                    </div>

                </div>

            </div>
            <div className="startupspark_eventinfo">
                <div className="startupspark_cont">
                    <div className="startupspark_vision">

                        <hr />
                        <div className="startupspark_headinfo">
                            <h2>Startup Spark : Session 1</h2>
                        </div>
                        <hr />
                        <div className="startupspark_vesta">
                            <div className="startupspark_image">
                                <img src="https://scontent.flko4-1.fna.fbcdn.net/v/t39.30808-6/302100655_445054684313452_3885763472828834571_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=gyF-CBNxU1MAX8sLh61&_nc_ht=scontent.flko4-1.fna&oh=00_AfCP-7impYfOpK2hBeA3thFHi3RC8fVCO9KPEcPl2lgaHQ&oe=655D3211" alt="Major Vandana Sharma" srcSet="" />
                            </div>
                            <div className="startupspark_about">
                                <h2>
                                    MAJOR VANDANA SHARMA
                                </h2>
                                <p>Major Vandana Sharma is a woman leader, entrepreneur, TEDx speaker, HR industry leader, career coach, and ex-military officer from the Indian Army. Her contribution.</p>
                            </div>
                        </div>
                    </div>
                    <div className="startupspark_outlines">
                        <div className="startupspark_scroll">SCROLL DOWN &darr;</div>
                        <hr />
                        <div className="startupspark_headinfo">
                            <h2>Startup Spark : Session 2</h2>
                        </div>
                        <hr />
                        <div className="startupspark_vesta">
                            <div className="startupspark_image">
                                <img src="https://scontent.flko4-1.fna.fbcdn.net/v/t1.6435-9/71500161_2513297168907643_7815008739592765440_n.jpg?stp=c167.0.666.666a_dst-jpg_s851x315&_nc_cat=102&ccb=1-7&_nc_sid=c21ed2&_nc_ohc=Fu79IzmvyyUAX8S2pll&_nc_ht=scontent.flko4-1.fna&oh=00_AfAxtsWUT7YShGUgOeCbnpDRk_N_ihZmBqmUjDOsIohX7A&oe=657FEF01" alt="Kalpit Veerwal" srcSet="" />
                            </div>
                            <div className="startupspark_about">
                                <h2>
                                    Kalpit Veerwal
                                </h2>
                                <p>He is a public speaker and has given 100+ guest lectures, Ted Talks & seminars in prestigious colleges like IITs, IIITs, and NITs (including the likes of IIT Bombay, IIT Kharagpur).</p>
                            </div>
                        </div>            </div>
                </div>
            </div>
            <div className="startupspark_faqs">
                FAQs
            </div>



            <div className="startupspark_footer">
                <div className="startupspark_copyright">
                    <p>&copy; copyright all rights reserved</p>
                </div>
                <div className="startupspark_links">

                </div>
                <div className="startupspark_socialmedia">

                </div>
            </div>
            {/* <script src='\src\Register.js'></script> */}
            {/* </body> */}
        </div>
    );
}

export default Startup;
