import React, { useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import Hamburger from 'hamburger-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function SideNav(props) {
    const [isOpen, setOpen] = useState(false);
    const [sidebarState, setSidebarState] = useState(null);
    const [scrolled, setScrolled] = useState(0);
    /*  0: default
        1: pagescrolled down
        2: page reload when scrolled down already
     */

    const scrollEvent = e => { if (window.scrollY > 250) setScrolled(1); else setScrolled(0); }
    useEffect(() => {
        window.addEventListener('scroll', scrollEvent);
        return () => {
            window.removeEventListener('scroll', scrollEvent);
        }
    });

    return (
        <>
            <Styled.Nav open={isOpen} scrolled={scrolled}>
                <img src={props.icon} className='logo' alt={props.alt} onClick={() => { window.open(props.url, '_self') }} />
                <Hamburger className='hamburger-react' toggled={isOpen} toggle={setOpen} onToggle={() => { setSidebarState(!isOpen) }} />
                <Styled.Sidebar className='sidebar' open={sidebarState} scrolled={scrolled}>
                    <ul className='list'>
                        {props.children}
                    </ul>
                </Styled.Sidebar>
            </Styled.Nav>
        </>
    );
}

function NavItem(props) {
    return (
        <li className='list-item'><Link id={props.id} to=''>&nbsp;{props.children}</Link></li>
    );
}

function Navbar(props) {
    const { pathname } = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        const home = (e) => {
            e.preventDefault();
            let otherpage = false;
            if (pathname !== '/') {
                otherpage = true;
                nav('/');
            }
            setTimeout(() => {
                const el = document.getElementById('home');
                if (el)
                    el.scrollIntoView({ behavior: (otherpage ? 'instant' : 'smooth'), block: 'start' });
            }, 0);
        }
        const homenav = document.getElementById('homenav');
        if (homenav)
            homenav.addEventListener('click', home);
        return () => {
            if (homenav)
                homenav.removeEventListener('click', home);
        }
    }, [pathname, nav]);

    useEffect(() => {
        const about = (e) => {
            e.preventDefault();
            if (pathname !== '/') {
                nav('/');
            }
            setTimeout(() => {
                const el = document.getElementById('about');
                if (el)
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 0);
        }
        const aboutnav = document.getElementById('aboutnav');
        if (aboutnav)
            aboutnav.addEventListener('click', about);
        return () => {
            if (aboutnav)
                aboutnav.removeEventListener('click', about);
        }
    }, [pathname, nav]);

    useEffect(() => {
        const events = (e) => {
            e.preventDefault();
            if (pathname !== '/') {
                nav('/');
            }
            setTimeout(() => {
                const el = document.getElementById('events');
                if (el)
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 0);
        }
        const eventsnav = document.getElementById('eventsnav');
        if (eventsnav)
            eventsnav.addEventListener('click', events);
        return () => {
            if (eventsnav)
                eventsnav.removeEventListener('click', events);
        }
    }, [pathname, nav]);

    useEffect(() => {
        const speakers = (e) => {
            e.preventDefault();
            if (pathname !== '/') {
                nav('/');
            }
            setTimeout(() => {
                const el = document.getElementById('speakers');
                if (el)
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 0);
        }
        const speakersnav = document.getElementById('speakersnav');
        if (speakersnav)
            speakersnav.addEventListener('click', speakers);
        return () => {
            if (speakersnav)
                speakersnav.removeEventListener('click', speakers);
        }
    }, [pathname, nav]);

    useEffect(() => {
        const contacts = (e) => {
            e.preventDefault();
            if (pathname !== '/') {
                nav('/');
            }
            setTimeout(() => {
                const el = document.getElementById('contacts');
                if (el)
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 0);
        }
        const contactsnav = document.getElementById('contactsnav');
        if (contactsnav)
            contactsnav.addEventListener('click', contacts);
        return () => {
            if (contactsnav)
                contactsnav.removeEventListener('click', contacts);
        }
    }, [pathname, nav]);


    useEffect(() => {
        const handleComingSoon = (event) => {
            event.preventDefault();
            window.alert("Coming soon ...");
        }
        const sponsorsnav = document.getElementById('sponsorsnav');
        if (sponsorsnav)
            sponsorsnav.addEventListener('click', handleComingSoon);
        return () => {
            if (sponsorsnav)
                sponsorsnav.removeEventListener('click', handleComingSoon);
        }
    }, []);



    return (
        <SideNav className="navbar" icon='/assets/iic-logo.png' url='#' alt='IIC-Udbhav'>
            <NavItem link='/' id="homenav" >Home</NavItem>
            <NavItem link='/about' id="aboutnav" >About</NavItem>
            <NavItem link='/events' id="eventsnav" >Events</NavItem>
            <NavItem link='/speakers' id="speakersnav" >Speakers</NavItem>
            {/* <NavItem link='/merchandise'>Merchandise</NavItem> */}
            <NavItem link='/sponsors' id="sponsorsnav">Sponsors</NavItem>
            <NavItem link='/contacts' id="contactsnav" >Contacts</NavItem>
        </SideNav>
    );
}

export { SideNav, NavItem, Navbar as default };