import pitch from "./images/UDBHAV.jpg";
import successvector from "./images/success.jpg";
import "./about.css";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from "react";
// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

function About() {
  const about = useRef(null);
  const line = useRef(null);
  const para1 = useRef(null);
  const para2 = useRef(null);
  const para3 = useRef(null);
  const img1 = useRef(null);
  const img2 = useRef(null);

  useEffect(() => {
    const animation1 = gsap.fromTo(para1.current, {
      xPercent: 150,
    }, {
      xPercent: 0,
      duration: 2,
      scrollTrigger: {
        trigger: para1.current,
        start: 'top 90%',
        end: '+=200 90%',
        scrub: 1,
        // markers: true,
      },
    });
    const animation2 = gsap.fromTo(para2.current, {
      xPercent: -150,
    }, {
      xPercent: 0,
      duration: 2,
      scrollTrigger: {
        trigger: para2.current,
        start: 'top 95%',
        end: '+=200 95%',
        scrub: 1,
        // markers: true,
      },
    });
    const animation3 = gsap.fromTo(para3.current, {
      xPercent: 150,
    }, {
      xPercent: 0,
      duration: 2,
      scrollTrigger: {
        trigger: para3.current,
        start: 'top 90%',
        end: '+=300 90%',
        scrub: 1,
        // markers: true,
      },
    });
    const animation4 = gsap.fromTo(line.current, {
      '--after-height': '0px',
    }, {
      '--after-height': `${about.current.clientHeight}px`,
      duration: 2,
      scrollTrigger: {
        trigger: about.current,
        start: 'top 50%',
        end: 'bottom 50%',
        scrub: 2,
        // markers: true,
      },
    });

    const animation5 = gsap.fromTo(img1.current, {
      xPercent: -150,
    }, {
      xPercent: 0,
      duration: 2,
      scrollTrigger: {
        trigger: img1.current,
        start: 'top 90%',
        end: '+=65% 90%',
        scrub: 1,
        // markers: true,
      },
    });
    const animation6 = gsap.fromTo(img2.current, {
      xPercent: 150,
    }, {
      xPercent: 0,
      duration: 2,
      scrollTrigger: {
        trigger: img2.current,
        start: 'top 90%',
        end: 'bottom 90%',
        scrub: 1,
        // markers: true,
      },
    });

    return () => {
      animation1.kill();
      animation2.kill();
      animation3.kill();
      animation4.kill();
      animation5.kill();
      animation6.kill();
    }
  }, []);

  return (
    <div id="about" ref={about}>
      <div className="lines">
        <div className="line1" ref={line}></div>
        <div className="l1"></div>
      </div>
      <div className="udbhav">
        <div className="pitch1">
          <img
            ref={img1}
            src={pitch}
            alt=""
            style={{
              borderBottomRightRadius: "15px",
              borderTopRightRadius: "15px",
              // Use a percentage to make it circular
            }}
          />
        </div>
        <div className="container ">
          <h1>
            WHAT IS UDBHAV <br />
            TECH??
          </h1>
          <p ref={para1}>
            Udbhav Tech is an annual flagship event by Institute Innovation
            Council(IIC) which comes directly under Ministry Of Human Resource
            And Development(MHRD), annually convening academia, venture
            capitalists, and forward thinking entrepreneurs. Join with us to
            witness innovation, creativity in action, film making with
            quizzes, workshops, comedy night and much more....
          </p>
        </div>
      </div>
      <div className="udbhav1">
        <div className="container2">
          <h1>ABOUT UDBHAV TECH THEME???</h1>
          <p ref={para2}>
            Udbhav Tech seeks to inspire everyone to break the limits of their
            minds and come out of their comfort zone, challenging conventional
            thinking, explore and redefine the boundaries to foster entrepreneur
            spirit among the students and community in general.
          </p>
        </div>
        <div className="success1">
          <img
            ref={img2}
            src={successvector}
            alt=""
            // width="550px"
            // height="428px"
            style={{
              borderBottomRightRadius: "15px",
              borderTopRightRadius: "15px",

              // Use a percentage to make it circular
            }}
          />
        </div>
      </div>
      <div className="udbhav3">
        <div className="bgimg" />
        <div className="wallstreet">
          <h1>
            UDBHAV TECH'23-
            <br />
            WALL STREET OF ENTREPRENEURS
          </h1>
          <div ref={para3}>
            Unleashing the theme of 'Wall Street Of Entrepreneurs' where uncharted
            realms await your curiosity's embrace. Venture into the unknown,
            question the unquestioned, and sculpt innovation from mere ideas. Let
            this fusion kindle a wildfire of revolutionary thinking, where
            entrepreneurs script tomorrows unseen.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
