import "./visionary.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index"
const firestore = getFirestore(app);

function Visionary() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(
        collref,
        where("event_participated", "==", "visionary")
      );
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "visionary",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signin");
    }
  };
  return (
    <div className="visionaryventures_">
      <ScrollToTop />
      <div>
        <div className="visionaryventures_wave"></div>
        <div className="visionaryventures_wave"></div>
        <div className="visionaryventures_wave"></div>
      </div>
      <Navbar />
      <div className="visionaryventures_container">
        <div className="visionaryventures_event">
          <div className="visionaryventures_eventname">
            <div className="visionaryventures_name">
              <h2>Visionary Ventures: Capturing Dreams, Igniting Futures</h2>
            </div>
          </div>
          <div className="visionaryventures_eventname">
            <p>
              Rolls, camera and action: ”Visionary Ventures” is here. It
              celebrates the entrepreneurial cinematic journey. This event of
              Udbhav gives you the opportunity to depict an entire
              entrepreneurial journey through your creativity. It gives you an
              opportunity to venture through your camera lenses , here's a set
              of guidelines including eligibility, team size, and other relevant
              details:
            </p>
          </div>
          <div className="visionaryventures_eventname">
            <button className="visionaryventures_reg" onClick={handleRegister}>
              Register &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="visionaryventures_eventinfo">
        <div className="visionaryventures_cont">
          <div className="visionaryventures_vision">
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Eligibility</h2>
            </div>
            <hr />
            <ul>
              <li>
                The film-making event is open to individuals or teams with a
                passion for storytelling through film.
              </li>
              <li>
                Participants can be students, professionals, or enthusiasts
                interested in the startup and entrepreneurship domain.
              </li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Team Size</h2>
            </div>
            <hr />
            <ul>
              <li>Teams can consist of 1 to 5 members</li>
              <li>
                Participants are encouraged to bring together diverse skills
                such as writing, directing, cinematography, and editing.
              </li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Theme</h2>
            </div>
            <hr />
            <ul>
              <li>
                The theme of the films should revolve around the journey of
                startups and entrepreneurs
              </li>
              <li>
                Participants are encouraged to highlight challenges, successes,
                and innovative aspects of the entrepreneurial process.
              </li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Film Duration</h2>
            </div>
            <hr />
            <ul>
              <li>Films should have a maximum duration of eight minutes.</li>
              <li>
                Short films are encouraged, focusing on impactful storytelling
                and creativity.
              </li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Equipment</h2>
            </div>
            <hr />
            <ul>
              <li>
                Participants are responsible for providing their own filming
                equipment (cameras, audio equipment, etc.)
              </li>
              <li>Editing can be done using any preferred software.</li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Judging Criteria</h2>
            </div>
            <hr />
            <ul>
              <li>
                Films will be judged based on creativity, storytelling,
                technical execution, and relevance to the theme.
              </li>
              <li>
                The judging panel may include professionals from the film
                industry, entrepreneurship, and startup domain.
              </li>
            </ul>
          </div>
          <div className="visionaryventures_outlines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Screening and Awards</h2>
            </div>
            <hr />
            <ul>
              <li>Selected films will be screened during the event.</li>
              <li>
                Awards will be given for categories such as Best Film, Best
                Cinematography, and Audience Choice
              </li>
            </ul>
          </div>
          <div className="visionaryventures_guidelines">
            <div className="visionaryventures_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="visionaryventures_headinfo">
              <h2>Code of Conduct</h2>
            </div>
            <hr />
            <ul>
              <li>
                Respectful and professional behavior is expected from all
                participants.
              </li>
              <li>
                Any content that is deemed offensive or violates ethical
                standards will result in disqualification.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="visionaryventures_faqs">FAQs</div>
      <details>
        <summary>
          What are the registration procedures?
          <span className="visionaryventures_icon">👇</span>
        </summary>
        <div className="visionaryventures_stats">
          <ul>
            <li>Registration opens 22.11.2023 and closes on 24.11.2023 .</li>
            <li>
              Teams must submit their intent to participate along with a brief
              synopsis of their film idea.
            </li>
            <li>
              A confirmation email will be sent upon successful registration.
            </li>
          </ul>
        </div>
      </details>
      <details>
        <summary>
          What are the submission requirements?
          <span className="visionaryventures_icon">👇</span>
        </summary>
        <div className="visionaryventures_stats">
          <ul>
            <li>Films must be submitted by 24.11.2023.</li>
            <li>
              Submissions should be made digitally, and details for submission
              will be provided upon registration.
            </li>
          </ul>
        </div>
      </details>

      <div className="visionaryventures_footer">
        <div className="visionaryventures_copyright">
          <h3>Contact Information:</h3>
          <p>For inquiries or assistance, contact any IIC member</p>
        </div>
        <div className="visionaryventures_links"></div>
        <div className="visionaryventures_socialmedia"></div>
      </div>
      {/* <script src='\src\Register.js'></script> */}
      {/* </body> */}
    </div>
  );
}

export default Visionary;
