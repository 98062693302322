import React, { useRef, useState } from "react";
import "./Signup.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
// import { signupSchema } from "../validation/signup_validation";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { app } from "../../firebase/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Udhbhav from "./img/Udbhav_logo_light__1_-removebg-preview.png";
import {
  faEnvelope,
  faLariSign,
  faLock,
  faPhone,
  faUser,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { authUser } from "./authentication";

const auth = getAuth(app);
const firestore = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

//signup component
const Signup = ({ setLogin }) => {
  const cont = useRef(null);
  const navigate = useNavigate();
  let name, value;

  //defining usestate while signing
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  //defining useState while loginin
  const [client, getUser] = useState({
    user_email: "",
    user_password: "",
  });

  // changing value as per the user enters
  const userData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  //changing value as the user loginIn
  const clientData = (e) => {
    name = e.target.name;
    value = e.target.value;

    getUser({ ...client, [name]: value });
  };

  const { firstName, lastName, email, password } = user;
  const { user_email, user_password } = client;

  //handling sign up logic
  const handleSignup = async (event) => {
    event.preventDefault();
    if (!email || !password || !firstName || !lastName) {
      toast.error("❌ Please fill all the details", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (password.length < 8) {
      toast.error("❌ Password should be more than 8 letters", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          const new_user = addDoc(collection(firestore, "iic_users"), {
            email: email,
            firstName: firstName,
            lastName: lastName,
            password: password,
          })
            .then((res) => {
              console.log("registration succesful", new_user);
              // window.alert("registration Successful");
              toast.success("🥳 Wow!! You got Registered", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              toast.info("✈️ Redirecting... to login Page", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setLogin(true);
              setTimeout(() => {
                navigate('/');
              }, 2000);
            })
            .catch((err) => {
              toast.warning("😣 Something went wrong", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              console.log(err);
            });
        })

        .catch((err) => {
          toast.error("❌ Email is already in use", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          console.log(err);
        });
    }
  };

  //handling login logic
  const handleLogin = async (event) => {
    event.preventDefault();

    signInWithEmailAndPassword(auth, user_email, user_password)
      .then((res) => {
        toast.success("🤗 Welcome back, Captian!!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        toast.info("✈️ Redirecting to home page", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLogin(true);
        setTimeout(() => {
          navigate('/');
        }, 2000);
      })
      .catch((err) => {
        toast.error("Invalid Email/Password", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        console.log(err);
      });
  };

  //sign-in with google logic
  const handleGoogle = async (event) => {
    event.preventDefault();

    signInWithPopup(auth, googleProvider)
      .then(() => {
        console.log(authUser);
        const new_user = addDoc(collection(firestore, "iic_users"), {
          email: authUser.email,
          firstName: authUser.displayName,
          lastName: "undefined",
          password: "**",
        });
      })
      .then(() => {
        toast.success("🥳 Wow!! You got Registered", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        toast.info("✈️ Redirecting... to Home Page", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLogin(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("😵 Can't Connect to google", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
  };

  //swtiching tabs when to switch
  const switchTabs = (e, tabs) => {
    if (tabs === "login") {
      cont.current.classList.remove("sign-up-mode");
    }
    if (tabs === "register") {
      cont.current.classList.add("sign-up-mode");
    }
  };

  const { values, errors, handleBlur, handleSubmit } = useFormik({
    initialValues: user,
    // validationSchema: signupSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <div className="container_login" ref={cont}>
        <div className="forms-container">
          <div className="signin-signup">
            {/* login-page */}
            <form action="#" className="sign-in-form" method="POST">
              <h2 className="title">Sign in</h2>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faEnvelope} />
                </i>
                <input
                  id="user_email"
                  type="email"
                  name="user_email"
                  required
                  value={client.user_email}
                  onChange={clientData}
                  placeholder="Email"
                  autoComplete="off"
                  onBlur={handleBlur}
                />
              </div>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faLock} />
                </i>
                <input
                  id="user_password"
                  type="password"
                  name="user_password"
                  required
                  value={client.user_password}
                  onChange={clientData}
                  placeholder="Password"
                  autoComplete="off"
                />
              </div>
              <a href="#" className="href">
                Forgot password :(
              </a>

              <button
                type="submit"
                className="btn_signin transparent-2"
                onClick={handleLogin}
              >
                Sign In
              </button>
              <p className="social-text">
                Follow us on social media to get latest updates
              </p>
              <div className="social-media">
                <a
                  href="http://mnnit.ac.in/iip/iic/index.html"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                </a>
                <a
                  href="https://www.instagram.com/iic_mnnit/"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.linkedin.com/company/iicmnnitallahabad/"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </form>

            {/* signup form */}
            <form action="#" className="sign-up-form" method="POST">
              <h2 className="title">Sign-up</h2>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faUser} />
                </i>
                <input
                  type="text"
                  name="firstName"
                  required
                  value={user.firstName}
                  onChange={userData}
                  placeholder="Enter your First Name"
                  autoComplete="off"
                  onBlur={handleBlur}
                />
                {/* <p className="form-error">{errors.firstName}</p> */}
              </div>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faUser} />
                </i>
                <input
                  type="text"
                  name="lastName"
                  required
                  value={user.lastName}
                  onChange={userData}
                  placeholder="Enter your Last Name"
                  autoComplete="off"
                  onBlur={handleBlur}
                />
                {/* <p className="form-error">{errors.lastName}</p> */}
              </div>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faPhone} />
                </i>
                <input
                  type="text"
                  name="email"
                  required
                  value={user.email}
                  onChange={userData}
                  placeholder="Enter your Email-Id"
                  autoComplete="off"
                  onBlur={handleBlur}
                />
                {/* <p className="form-error">{errors.email}</p> */}
              </div>
              <div className="input-field">
                <i>
                  <FontAwesomeIcon icon={faLock} />
                </i>
                <input
                  type="password"
                  name="password"
                  required
                  value={user.password}
                  onChange={userData}
                  placeholder="Password"
                  autoComplete="off"
                  onBlur={handleBlur}
                />
                {/* <p className="form-error">{errors.password}</p> */}
              </div>

              <button
                type="submit"
                className="btn_signin transparent-2"
                onClick={handleSignup}
              >
                Register
              </button>
              <button
                type="button"
                className="login-with-google-btn"
                onClick={handleGoogle}
              >
                Sign in with Google
              </button>
              <p className="social-text">
                Follow us on social media to get latest updates
              </p>
              <div className="social-media">
                <a
                  href="http://mnnit.ac.in/iip/iic/index.html"
                  target="_blank"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                </a>
                <a
                  href="https://www.instagram.com/iic_mnnit/"
                  target="_blank"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="https://www.linkedin.com/company/iicmnnitallahabad/"
                  className="social-icon"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </div>
            </form>
          </div>
        </div>

        {/* to siwtch tabs page */}
        <div className="panels-container">
          <div className="panel left-panel">
            <div className="content">
              <img src={Udhbhav} className="image" alt="Login" />
              <p>
                Be the part of the most <strong>Exciting Festival</strong>
              </p>
              <h3>
                <strong>Signup Now</strong>
              </h3>
              <button
                className="btn_signin transparent"
                id="sign-up-btn"
                onClick={(e) => switchTabs(e, "register")}
              >
                Sign Up
              </button>
            </div>
            {/* <img src={Udhbhav} className="image" alt="Register" /> */}
          </div>
          <div className="panel right-panel">
            <div className="content">
              <img src={Udhbhav} className="image" alt="Login" />
              <h4>Already a member?</h4>
              <button
                className="btn_signin transparent"
                id="sign-in-btn"
                onClick={(e) => switchTabs(e, "login")}
              >
                Sign In
              </button>
            </div>
            {/* <img src={Udhbhav} className="image" alt="Login" /> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Signup;
