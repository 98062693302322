import React, { useCallback, useRef } from 'react'
import * as Styled from './styles';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import * as particlesOptions from './particles.json';
import { Link, useNavigate } from 'react-router-dom';

function Home({ isLogin, logout }) {
    const home = useRef(null);
    const background = useRef(null);
    const nav = useNavigate();

    const particlesInit = useCallback(async (engine) => {
        // console.log(engine);
        await loadFull(engine);
    }, []);
    const particlesLoaded = useCallback(async (container) => {
        // console.log(container);
    }, []);

    const handleButtonClick = (e) => {
        e.preventDefault();
        if (!isLogin)
            nav('/signin');
        else
            logout();
    }

    return (
        <>
            <Styled.Home id='home' ref={home}>
                <div className='bg_container' ref={background}><Particles id='tsparticles1' width='100vw' height='100vh' options={particlesOptions} init={particlesInit} loaded={particlesLoaded} /></div>
                <div className='details_home'>
                    <Styled.Logo>
                        <img src='assets/Udbhav_logo_dark.png' alt='Logo' />
                    </Styled.Logo>
                    <Styled.Date>
                        <span id='left_span' />24th-26th November<span id='right_span' />
                    </Styled.Date>
                    <Link to='/signin'>
                        <Styled.Register onClick={handleButtonClick}>
                            Sign {isLogin ? 'Out' : 'In'}
                        </Styled.Register>
                    </Link>
                </div>
            </Styled.Home>
        </>
    )
}

export default Home;