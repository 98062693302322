import "./questyouth.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index";
const firestore = getFirestore(app);

function Questyouth() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(
        collref,
        where("event_participated", "==", "quest_youth")
      );
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "quest_youth",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signin");
    }
  };
  return (
    <div className="questyouth_">
      <ScrollToTop />
      {/* <body> */}
      <div>
        <div className="questyouth_wave"></div>
        <div className="questyouth_wave"></div>
        <div className="questyouth_wave"></div>
      </div>
      <Navbar />
      <div className="questyouth_container">
        <div className="questyouth_event">
          <div className="questyouth_eventname">
            <div className="questyouth_name">
              <h2>QuestYouth - Exploring Paths to Success</h2>
            </div>
          </div>
          <div className="questyouth_eventname">
            <p>
              Embark on a journey of knowledge and discovery at QuestYouth, an
              exhilarating component of the Youth Summit-Udbhav proudly
              presented by the Institution's Innovation Council (IIC).
              QuestYouth is not just a quiz; it's an exploration of the realms
              of business, technology, and entrepreneurship. With a unique blend
              of intellect and excitement, this event promises to engage and
              inspire participants as they navigate the paths to success.
            </p>
          </div>
          <div className="questyouth_eventname">
            <button className="questyouth_reg" onClick={handleRegister}>
              Register &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="questyouth_eventinfo">
        <div className="questyouth_cont">
          <div className="questyouth_vision">
            <hr />
            <div className="questyouth_headinfo">
              <h2>Team Size</h2>
            </div>
            <hr />
            <p>
              Form a team of up to three members or go solo as a lone wolf.
              Whether you thrive in collaboration or prefer the lone path,
              QuestYouth welcomes all who seek to explore and conquer the quiz
              challenge.
            </p>
            <ul>
              <li>Teams can consist of a maximum of three members.</li>
              <li>Lone wolf entries are allowed.</li>
            </ul>
          </div>
          <div className="questyouth_outlines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Registrations</h2>
            </div>
            <hr />
            <ul>
              <li>
                All participants must register for QuestYouth to be eligible.
              </li>
              <li>
                Registration details will be provided through the official event
                portal.
              </li>
              <li>
                All the members participating in the event need to register on
                the website and only the team leader must fill the google-form
                provided for the team.
              </li>
            </ul>
          </div>
          <div className="questyouth_outlines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Innovation Quiz</h2>
            </div>
            <hr />
            <p>
              Dive into the world of innovation with a thought-provoking quiz.
              QuestYouth challenges participants on various facets of business,
              technology, and entrepreneurship, providing a platform to showcase
              their knowledge and analytical skills.
            </p>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Written Preliminary Round</h2>
            </div>
            <hr />
            <p>
              Kickstart the competition with a written preliminary round. Teams
              will face a series of questions designed to test their
              understanding of innovation, business trends, and technological
              advancements.
            </p>
            <ul>
              <li>
                The written preliminary round will consist of questions related
                to business, technology, and entrepreneurship.
              </li>
              <li>
                Teams must answer the questions within the specified time frame.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Final Round</h2>
            </div>
            <hr />
            <p>
              The top-performing teams from the preliminary round will advance
              to the final showdown. Prepare for an exhilarating final round
              where the stakes are higher, and victory awaits the team that
              conquers the QuestYouth challenge.
            </p>
            <ul>
              <li>
                Top-performing teams from the written preliminary round will
                advance to the final round.
              </li>
              <li>
                The final round will be conducted in a competitive and engaging
                format.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2> Quiz Conduct</h2>
            </div>
            <hr />
            <ul>
              <li>
                Participants are expected to maintain the integrity of the quiz.
              </li>
              <li>
                Any form of cheating or unfair practices will result in
                disqualification.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2> Quizmaster's Decision</h2>
            </div>
            <hr />
            <p>
              {" "}
              The decisions made by the Quizmaster are final. Trust in the
              expertise of our Quizmaster as they guide the event, ensuring a
              fair and exciting competition for all participants.
            </p>
            <ul>
              <li>The decisions of the Quizmaster are final and binding.</li>
              <li>
                Participants must adhere to the instructions and rulings
                provided by the Quizmaster.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Event Etiquette</h2>
            </div>
            <hr />
            <ul>
              <li>Respectful behavior is expected at all times.</li>
              <li>
                Participants should display sportsmanship and camaraderie.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2>Late Arrivals</h2>
            </div>
            <hr />
            <ul>
              <li>Participants must adhere to the event schedule.</li>
              <li>
                Latecomers may not be accommodated, and their participation
                could be at risk.
              </li>
            </ul>
          </div>
          <div className="questyouth_guidelines">
            <div className="questyouth_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="questyouth_headinfo">
              <h2> Prizes</h2>
            </div>
            <hr />
            <ul>
              <li>Prizes will be awarded to the top-performing teams.</li>
              <li>
                Recognition and certificates will be provided to acknowledge
                participants' achievements.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="questyouth_faqs">FAQs</div>

      <details>
        <summary>
          What is the team dynamics for the event?
          <span className="questyouth_icon">👇</span>
        </summary>
        <div className="questyouth_stats">
          <p>
            Form a team of up to three members or go solo as a lone wolf.
            Whether you thrive in collaboration or prefer the lone path,
            QuestYouth welcomes all who seek to explore and conquer the quiz
            challenge.
          </p>
        </div>
      </details>
      <details>
        <summary>
          When will the registrations start?
          <span className="questyouth_icon">👇</span>
        </summary>
        <div className="questyouth_stats">
          <p>The registrations are started.</p>
        </div>
      </details>

      <div className="questyouth_footer">
        <div className="questyouth_copyright">
          <h2>DISCLAIMER</h2>
          <ul>
            <li>
              The organizing committee reserves the right to modify rules if
              necessary.
            </li>
            <li>
              All participants are expected to adhere to the rules and decisions
              of the organizers
            </li>
          </ul>
        </div>
        <div className="questyouth_links"></div>
        <div className="questyouth_socialmedia"></div>
      </div>
      {/* <script src='\src\Register.js'></script> */}
      {/* </body> */}
    </div>
  );
}

export default Questyouth;
