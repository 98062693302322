import React from 'react';

import './Speakers.css';
import Img1 from './Images/vandana.jpg'
import Img2 from './Images/nisha.jpg'
import Img3 from './Images/kalpit.jpg'
import Img4 from './Images/sandeep_jain.jpg'
import Img5 from './Images/Link.png'
import Img6 from './Images/X.jpg'
import Img7 from './Images/face.jpg'
import Img8 from './Images/Insta.png'

function Speakers() {

  return (
    <div className="speakers" id='speakers'>
      <div className="container_speakers">
        <div className="outer">
          <div className="details">
            <h1 className='h1_speakers'>SPEAKERS</h1>
            <h2>
              <span>Udhbav,where words meet wisdom</span>
              <span>and knowledge finds its voice</span>
            </h2>
            <div className="container1">
              <a href="#starting"><button className="btn btn1">OUR SPEAKERS</button><link to='main12'></link></a>
              <a href='#'><button className="btn btn1">HOME PAGE</button></a>
            </div>
          </div>
        </div>
      </div>

      <div className="main-block-of-program" id="starting">
        <div className="hero">
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>
          <div className="cube"></div>

        </div>

        <div className="big-block">
          <h2 className="Main-heading">Major Vandana Sharma</h2>
          <img className="img" src={Img1} alt="" />
          <div className="secondary-heading"><h2 className="title_speaker">Ex Army</h2>
            <p>Major Vandana Sharma is a woman leader, entrepreneur, TEDx speaker, HR industry leader, career coach, and ex-military officer from the Indian Army. Her contribution.</p>
            <div className="logo1">
              <a href="https://www.linkedin.com/in/majorvansharma/?originalSubdomain=in" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img5} alt="" /></a>
              <a href="https://twitter.com/MajorvanSharma" target="_blank" rel="noreferrer"><img style={{ borderRadius: '50%' }} className="logo_speaker" src={Img6} alt="" /></a>
              <a href="https://www.facebook.com/MajorVanSharma/" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img7} alt="" /></a>
            </div>

          </div>
        </div>
        <div className="big-block">
          <h2 className="Main-heading">Dr. Nisha Khanna</h2>
          <img className="img" src={Img2} alt="" />
          <div className="secondary-heading"><h2 className="title_speaker">Renowned Psychologist</h2>
            <p>Dr Nisha Khanna is among the leading Best Marriage Counsellors/ Psychologists and has been more popular in Delhi NCR and India as a Marriage and Family Counsellor.</p>
            <div className="logo1">
              <a href="https://www.linkedin.com/in/drnishakhanna/?originalSubdomain=in" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img5} alt="" /></a>
              <a href="https://twitter.com/byetense?lang=en" target="_blank" rel="noreferrer"><img style={{ borderRadius: '50%' }} className="logo_speaker" src={Img6} alt="" /></a>
              <a href="https://www.facebook.com/drnishakhanna" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img7} alt="" /></a>
            </div>
          </div>
        </div>
        <div className="big-block">
          <h2 className="Main-heading">Kalpit Veerwal</h2>
          <img className="img" src={Img3} alt="" />

          <div className="secondary-heading"><h2 className="title_speaker">CEO Acadboost</h2>
            <p>He is a public speaker and has given 100+ guest lectures, Ted Talks & seminars in prestigious colleges like IITs, IIITs, and NITs (including the likes of IIT Bombay, IIT Kharagpur).</p>
            <div className="logo1">
              <a href="https://www.linkedin.com/in/kalpitveerwal/?originalSubdomain=in" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img5} alt="" /></a>
              <a href="https://twitter.com/kalpitveerwal?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" rel="noreferrer"><img style={{ borderRadius: '50%' }} className="logo_speaker" src={Img6} alt="" /></a>
              <a href="https://www.facebook.com/kalpitveerwal22" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img7} alt="" /></a>
            </div>
          </div>
        </div>
        <div className="big-block">
          <h2 className="Main-heading">Sandeep Jain</h2>
          <img className="img" src={Img4} alt="" />

          <div className=" secondary-heading"><h2 className="title_speaker">CEO Geeks For Geeks</h2>
            <p>Mr. Sandeep Jain is the founder at GeeksforGeeks. He is an alumini of Indian Institute of Technology Roorkee. His platform GeeksforGeeks is well recognized among all the engineering students throughout all colleges in India.</p>
            <div className="logo1">
              <a href="https://www.linkedin.com/in/sandeep-jain-/?originalSubdomain=in" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img5} alt="" /></a>
              <a href="https://twitter.com/sandeep_jain?lang=en" target="_blank" rel="noreferrer"><img style={{ borderRadius: '50%' }} className="logo_speaker" src={Img6} alt="" /></a>
              <a href="https://www.instagram.com/sandeep_gfg/?hl=en" target="_blank" rel="noreferrer"><img className="logo_speaker" src={Img8} alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Speakers;