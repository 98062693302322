import "./Youthrise.css";
import React from "react";
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';
import { authUser } from "../../Register/authentication";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { app } from "../../../firebase/index";
const firestore = getFirestore(app);

function Youthrise() {
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (authUser != null) {
      console.log(authUser.email);

      const collectionref = collection(firestore, "iic_users");
      const docu = query(collectionref, where("email", "==", authUser.email));
      const user = await getDocs(docu);
      const user_id = user.docs[0].id;

      const collref = collection(firestore, `iic_users/${user_id}/events`);
      const eve = query(
        collref,
        where("event_participated", "==", "youth_rise")
      );
      const ispart = await getDocs(eve);

      console.log(ispart);

      if (!ispart.empty) {
        window.alert("You have already registered for the event");
      } else {
        window.alert("Registration for this Event is done");
        addDoc(collection(firestore, `iic_users/${user_id}/events`), {
          event_participated: "youth_rise",
        });
      }
    } else {
      window.alert("log in first");
      navigate("/signup_login");
    }
  };
  return (
    <div className="youthrise_">
      <ScrollToTop />
      {/* <body> */}
      <div>
        <div className="youthrise_wave"></div>
        <div className="youthrise_wave"></div>
        <div className="youthrise_wave"></div>
      </div>
      <Navbar />
      <div className="youthrise_container">
        <div className="youthrise_event">
          <div className="youthrise_eventname">
            <div className="youthrise_name">
              <h2>YouthRise : Exploring Solutions, Embarking Brilliance</h2>
            </div>
          </div>
          <div className="youthrise_eventname">
            <p>
              Welcome to another spectacular event of Youth Summit-Udbhav –
              Youth Rise. This phase is where you get the opportunity to
              innovate solutions to difficult business problems which will help
              you to blossom, and transform into innovative minds that have the
              power to change the world. For the "YouthRise" case study solving
              hackathon, here's a set of guidelines including eligibility, team
              size, and other relevant details:
            </p>
          </div>
          <div className="youthrise_eventname">
            <button className="youthrise_reg" onClick={handleRegister}>
              Register &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="youthrise_eventinfo">
        <div className="youthrise_cont">
          <div className="youthrise_vision">
            <hr />
            <div className="youthrise_headinfo">
              <h2> Eligibility</h2>
            </div>
            <hr />
            <p>
              Open to all students of currently pursuing an undergraduate or
              postgraduate degree
            </p>
          </div>

          <div className="youthrise_outlines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2>Team Size</h2>
            </div>
            <hr />

            <ul>
              <li>Teams can consist of 2 to 4 members.</li>
              <li>
                Participants are encouraged to form interdisciplinary teams to
                bring diverse perspectives to the case study.
              </li>
            </ul>
          </div>
          <div className="youthrise_outlines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2>Case Study</h2>
            </div>
            <hr />
            <ul>
              <li>
                The case study will be provided at the beginning of the
                hackathon.
              </li>
              <li>
                Participants are expected to analyze the case, identify
                challenges, and propose innovative solutions.
              </li>
            </ul>
          </div>
          <div className="youthrise_outlines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2>Tools and Resources</h2>
            </div>
            <hr />
            <ul>
              <li>
                Solutions will be evaluated based on creativity, feasibility,
                and the potential impact of the proposed solution.
              </li>
              <li>
                Judges will consider the presentation quality and how well the
                solution addresses the key challenges presented in the case
                study.
              </li>
            </ul>
          </div>
          <div className="youthrise_guidelines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2>Prizes</h2>
            </div>
            <hr />
            <p>Prizes will be awarded to the top three teams.</p>
          </div>
          <div className="youthrise_guidelines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2> Code of Conduct</h2>
            </div>
            <hr />
            <ul>
              <li>
                Respectful and professional behavior is expected from all
                participants.
              </li>
              <li>
                Any form of cheating, plagiarism, or unethical conduct will
                result in disqualification.
              </li>
            </ul>
          </div>
          <div className="youthrise_guidelines">
            <div className="youthrise_scroll">SCROLL DOWN &darr;</div>
            <hr />
            <div className="youthrise_headinfo">
              <h2> Judging Criteria</h2>
            </div>
            <hr />
            <ul>
              <li>
                Solutions will be evaluated based on creativity, feasibility,
                and the potential impact of the proposed solution.
              </li>
              <li>
                Judges will consider the presentation quality and how well the
                solution addresses the key challenges presented in the case
                study.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="youthrise_faqs">FAQs</div>

      <details>
        <summary>
          What is the schedule of the event?
          <span className="youthrise_icon">👇</span>
        </summary>
        <div className="youthrise_stats">
          <ul>
            <li>9:00 AM - Welcome</li>
            <li> 9:30 AM - Case Study Presentation</li>
            <li> 10:00 AM - Hacking Session</li>
            <li> 11:00 AM - Presentation and Judging</li>
            <li> 11:30 AM - Awards and Closing Ceremony</li>
          </ul>
        </div>
      </details>
      <details>
        <summary>
          What is the guideline for presentation of the Event?
          <span className="youthrise_icon">👇</span>
        </summary>
        <div className="youthrise_stats">
          <ul>
            <li>
              Each team will have a specified amount of time (e.g., 10 minutes)
              to present their solution to the judging panel.
            </li>
            <li>
              A Q&A session with the judges will follow each presentation.
            </li>
          </ul>
        </div>
      </details>

      <div className="youthrise_footer">
        <div className="youthrise_copyright">
          <h2>Contact Information</h2>
          <p>For inquiries or assistance, contact any IIC member .</p>
        </div>
        <div className="youthrise_links"></div>
        <div className="youthrise_socialmedia"></div>
      </div>
      {/* <script src='\src\Register.js'></script> */}
      {/* </body> */}
    </div>
  );
}

export default Youthrise;
