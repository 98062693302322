import './App.css';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/SideNav/nav';
import Home from './components/Home';
import About from './components/About/About';
import { Events } from './components/Events/Events';
import Sponsor from './components/Sponsors/Sponsor';
import Speakers from './components/Speakers/Speakers';
import Signup from './components/Register/Signup';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import { useEffect, useState } from 'react';
import { auth } from './firebase/index';
// Events
import Bizblitz from './components/Events/events/bizblitz';
import Cultural from './components/Events/events/cultural';
import Cohort from './components/Events/events/cohort';
import Pitchperfect from './components/Events/events/pitchperfect';
import Questyouth from './components/Events/events/questyouth';
import Seedstart from './components/Events/events/seedstart';
import Startup from './components/Events/events/startup';
import Visionary from './components/Events/events/visionary';
import Youthrise from './components/Events/events/Youthrise';

function App() {
	const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin') ? localStorage.getItem('isLogin') : false);

	const handleLogin = (val) => {
		setIsLogin(val);
		localStorage.setItem('isLogin', val);
	}
	const handleLogout = () => {
		setIsLogin(false);
		localStorage.removeItem('isLogin');
		try {
			auth.signOut();
			console.log('Logged out');
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<>
			<HashRouter>
				<Routes>
					<Route path='/' element={
						<>
							<Navbar />
							<Home isLogin={isLogin} logout={handleLogout} />
							<About />
							<Events />
							<Speakers />
							{/* <Sponsor /> */}
							<Contacts />
							<Footer />
						</>
					} />
					<Route path='/signin' element={<Signup setLogin={(val) => { handleLogin(val) }} />} />
					<Route path='/events'>
						<Route path='bizblitz' element={<Bizblitz />} />
						<Route path='cultural' element={<Cultural />} />
						<Route path='cohort' element={<Cohort />} />
						<Route path='pitchperfect' element={<Pitchperfect />} />
						<Route path='questyouth' element={<Questyouth />} />
						<Route path='seedstart' element={<Seedstart />} />
						<Route path='startupspark' element={<Startup />} />
						<Route path='visionaryventures' element={<Visionary />} />
						<Route path='youthrise' element={<Youthrise />} />
					</Route>
				</Routes>
			</HashRouter>
		</>
	);
}

export default App;
