import styled from "styled-components";

const Home = styled.div`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .details_home {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 1;
    }
    .bg_container {
        display: block;
        position: absolute;
        z-index: 0;
        min-height: 100vh;
        min-width: 100vw;
        width: 100%;
        height: 100%;
        background-image: var(--color5);
        background-size: cover;
        background-position-x: center;
    }
    a {
        text-decoration: none;
    }
`;

const Logo = styled.div`
    width: 42%;
    aspect-ratio: 16/8;
    margin-bottom: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 129%;
    }
    transition: transform 0.5s ease-in-out;
    &:hover {
        transform: scale(1.08);
    }
    @media (max-width: 900px) {
        width: 65%;
    }
    @media (max-width: 500px) {
        width: 85%;
    }
`;

const Date = styled.div`
    font-family: var(--font1);
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 15px;
    padding: 0px 10px;
    @media (max-width: 900px) {font-size: 2.2rem;}
    @media (max-width: 500px) {font-size: 1.9rem;}
    @media (max-height: 450px) {font-size:1.5rem; margin-top:-25px;}
    /* font-weight: 700; */
    width: fit-content;
    overflow: hidden;
    
    color: var(--color1);
    /* -webkit-text-stroke: #ffffff 0.5px; */
    /* letter-spacing: 1.5px; */
    position: relative;
    &::before {
        content: '';
        border: 1px solid var(--color4);
        width: 50%;
        position: absolute;
        top: 0px;
        left: -50%;
        /* animation: border-motion-top 3s linear infinite both 1s; */
        @keyframes border-motion-top {
            0% {
                left: -50%;
            }
            100% {
                left: 150%;
            }
        }
    }
    &::after {
        content: '';
        border: 1px solid var(--color4);
        width: 50%;
        position: absolute;
        bottom: 0px;
        right: -100%;
        /* animation: border-motion-bottom 3s linear infinite both 1s; */
        @keyframes border-motion-bottom {
            0% {
                right: -50%;
            }
            100% {
                right: 150%;
            }
        }
    }
    #left_span {
        content: '';
        background-color: transparent;
        border: 1px solid var(--color4);
        position: absolute;
        left: 0px;
        height: 100%;
        bottom: -100%;
        /* animation: border-motion-left 3s linear infinite both; */
        @keyframes border-motion-left {
            0% {
                bottom: -100%;
            }
            100% {
                bottom: 250%; /* moving out left bar out a little faster because of padding*/
            }
        }
    }
    #right_span {
        content: '';
        background-color: transparent;
        border: 1px solid var(--color4);
        position: absolute;
        right: 0px;
        height: 100%;
        top: -100%;
        /* animation: border-motion-right 3s linear infinite both; */
        @keyframes border-motion-right {
            0% {
                top: -100%;
            }
            100% {
                top: 250%; /* moving out left bar out a little faster because of padding*/
            }
        }
    }
`;

const Register = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    font-family: var(--font2);
    font-size: 2em;
    @media (max-width: 900px) {
        font-size: 1.8em;
        letter-spacing: 2px;
    }
    @media (max-width: 500px) {
        font-size: 1.2em;
        margin-top: 20px;
    }
    border: 2px solid rgb(255 237 227);
    border-radius: 8px;
    padding: 2px 20px;
    color: rgb(255 237 227);
    transition: all 0.1s ease-in;
    &:hover {
        transition: transform 0.1s ease-in;
        background-color: rgb(255 237 227);
        color: rgb(13 21 38);
    }
`;

export { Home, Logo, Date, Register };