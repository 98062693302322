import './pitchperfect.css';
import React from 'react';
import Navbar from '../../SideNav/nav';
import ScrollToTop from '../../ScrollToTop';

function Pitchperfect() {

    return (
        <div className='pitchperfect_'>
            <ScrollToTop />
            <div>
                <div className="pitchperfect_wave"></div>
                <div className="pitchperfect_wave"></div>
                <div className="pitchperfect_wave"></div>
            </div>
            <Navbar />
            <div className="pitchperfect_container" >
                <div className="pitchperfect_event">
                    <div className="pitchperfect_eventname">
                        <div className="pitchperfect_name">
                            <h2 style={{ color: " #0D1526" }}>PITCH PERFECT</h2>
                        </div>
                    </div>
                    <div className="pitchperfect_eventname">
                        <p style={{ color: " #0D1526" }}>
                            A session where industry experts,
                            investors and startup founders give valuable
                            insights from their entrepreneurial journey and
                            interact with the students .
                        </p>

                    </div>
                    <div className="pitchperfect_eventname">
                    </div>

                </div>

            </div>
            <div className="pitchperfect_eventinfo">
                <div className="pitchperfect_cont">
                    <div className="pitchperfect_vision">

                        <hr />
                        <div className="pitchperfect_headinfo">
                            <h2>Pitch Perfect : Session 1</h2>
                        </div>
                        <hr />
                        <div className="pitchperfect_vesta">
                            <div className="pitchperfect_image">
                                <img src="https://max-website20-images.s3.ap-south-1.amazonaws.com/doctor_nisha_b6e358f471.jpg" alt="Dr. Nisha Khanna" srcSet="" />
                            </div>
                            <div className="pitchperfect_about">
                                <h2>
                                    Dr. Nisha Khanna
                                </h2>
                                <p>Dr Nisha Khanna is among the leading Best Marriage Counsellors/ Psychologists and has been more popular in Delhi NCR and India as a Marriage and Family Counsellor.</p>
                            </div>
                        </div>
                    </div>
                    <div className="pitchperfect_outlines">
                        <div className="pitchperfect_scroll">SCROLL DOWN &darr;</div>
                        <hr />
                        <div className="pitchperfect_headinfo">
                            <h2>Pitch Perfect : Session 2</h2>
                        </div>
                        <hr />
                        <div className="pitchperfect_vesta">
                            <div className="pitchperfect_image">
                                <img src="https://pbs.twimg.com/profile_images/1383660243587002368/DJQEGX2o_400x400.jpg" alt="Mr. Sandeep Jain" srcSet="" />
                            </div>
                            <div className="pitchperfect_about">
                                <h2>
                                    Mr. Sandeep Jain
                                </h2>
                                <p>Mr. Sandeep Jain is the founder at GeeksforGeeks. He is an alumini of Indian Institute of Technology Roorkee. His platform GeeksforGeeks is well recognized among all the engineering students throughout all colleges in India.</p>
                            </div>
                        </div>            </div>
                </div>
            </div>


            <div className="pitchperfect_footer">
                <div className="pitchperfect_copyright">
                    <p>&copy; copyright all rights reserved</p>
                </div>
                <div className="pitchperfect_links">

                </div>
                <div className="pitchperfect_socialmedia">

                </div>
            </div>

        </div>
    );
}

export default Pitchperfect;
